<template>
  <Menu as="div" class="ml-3 relative ">
    <div>
      <MenuButton
        class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        <span class="sr-only">Open user menu</span>
        <img class="h-8 w-8 rounded-full" :src="profilePhoto" alt="" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100 " leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="flex flex-col absolute right-0 mt-2 w-56 px-2 py-2 border-2 border-gray-100
        rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
        dark:bg-backgroundDark dark:border-gray-800 z-20 dropdown_menu ">
        <div>
          <SwitchGroup as="div" class="flex items-center space-x-10" @click="onDarkModeToggle">

            <SwitchLabel class="block px-4 py-2 text-sm text-gray-700 dark:text-white">Dark Mode</SwitchLabel>
            <Switch as="button" v-model="darkModeEnabled"
              class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-400 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline"
              :class="darkModeEnabled ? 'bg-indigo-500' : 'bg-gray-200'" v-slot="{ checked }">
              <span class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }">
                <SunIcon v-if="!darkModeEnabled" class="w-5 self-center" />
                <MoonIcon v-if="darkModeEnabled" class="w-5 self-center dark:text-black" />
              </span>
            </Switch>
          </SwitchGroup>
        </div>
        <MenuItem v-slot="{ active }">
        <router-link :to="{ name: 'Profile', params: { id: id}}" 
          :class="[active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-white']"
          class="block px-4 py-2 text-sm text-gray-700 border-b-0">Your Profile</router-link>
        </MenuItem>
        <MenuItem v-slot="{ active }">
        <router-link :to="{ name: 'editProfileContact', params: { id: id } }"
          :class="[active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-white']"
          class="block px-4 py-2 text-sm text-gray-700 border-b-0">Edit Profile</router-link>
        </MenuItem>
        <MenuItem v-slot="{ active }">
        <router-link :to="{ name: 'Settings',params: { id: id } }"
          :class="[active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-white']"
          class="block px-4 py-2 text-sm text-gray-700 border-b-0">Edit Settings</router-link>
        </MenuItem>
        <MenuItem v-slot="{ active }">
        <router-link :to="{ name: 'Logout' }"
          :class="[active ? 'bg-gray-100 dark:bg-gray-600' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-white']"
          class="block px-4 py-2 text-sm text-gray-700 border-b-0">Sign out</router-link>
        </MenuItem>

      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { MoonIcon, SunIcon } from '@heroicons/vue/outline'
export default {
  name: "UserDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch,
    SwitchGroup,
    SwitchLabel,
    MoonIcon,
    SunIcon
  },
  setup() {
    const store = useStore()
    const darkModeEnabled = ref(store.getters.getDarkMode)

    store.dispatch('fetchUserProfilePicture')

    return {
      darkModeEnabled,
      onDarkModeToggle() {
        store.commit('setDarkMode', darkModeEnabled.value)
      },
      id: computed(() => store.getters.getId),
      profilePhoto: computed(() => store.getters.getUserProfilePicture),
    }
  },
}
</script>
